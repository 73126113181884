
class GrapeUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;

		this.registerToolbarButtons();
	}

	registerToolbarButtons ()
	{
		//Container
		FroalaEditor.DefineIcon('insertContainer', { NAME: 'square', template: 'font_awesome_5' });
		FroalaEditor.RegisterCommand('insertContainer', {
			title: 'Insert Container',
			focus: true,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				this.html.insert(`<div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #FFFFFF; box-shadow: 0 2px 10px rgba(0,0,0,0.1);"><p></p></div>`);
				this.undo.saveStep();
			}
		});

		//anchor btn
		FroalaEditor.DefineIcon('insertAnchorBtn', { NAME: 'mouse', template: 'font_awesome_5' });
		FroalaEditor.RegisterCommand('insertAnchorBtn', {
			title: 'Insert Link Button',
			focus: true,
			undo: true,
			refreshAfterCallback: true,
			callback: function () {
				this.html.insert(`<a href="#" style="display: inline-block; padding: 10px 30px; background-color: #1A2238; color: #FFFFFF; text-decoration: none; border-radius: 5px; font-size: 16px; margin-top: 10px;">View Shipment</a>`);
				this.undo.saveStep();
			}
		});
	}

	afterInit()
	{
		const el = document.getElementsByClassName('ps-navbar-container')[0];
		ko.applyBindings({}, el);
	}

	beforePageLoad()
	{
		// Show loader
	}

	afterPageLoad()
	{
		// Hide loader
	}
}

export default GrapeUIPlugin;
